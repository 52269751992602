import { FC } from 'react';
import { Box } from '@mui/material';
import { FlexBox } from './flex-box';
import PlayStore from './icons/PlayStore';
import AppleStore from './icons/AppleStore';

const AppStore: FC = () => {
  // data
  const appList = [
    {
      icon: PlayStore,
      title: 'Google Play',
      subtitle: '다운로드',
      url: 'https://play.google.com/store/apps/details?id=com.wineone.kmwine.user'
    },
    {
      icon: AppleStore,
      title: 'App Store',
      subtitle: '다운로드 하기',
      url: 'https://apps.apple.com/us/app/apple-store/id1637295910'
    }
  ];

  return (
    <FlexBox flexWrap="wrap">
      {appList.map((item, i) => (
        <a href={item.url} key={`store-${i}`} target="_blank" rel="noreferrer noopener">
          <Box
            m={0.5}
            gap={1}
            p="8px 10px"
            color="white"
            display="flex"
            bgcolor="#161d2b"
            borderRadius="5px"
            alignItems="center"
            width="124px"
          >
            <item.icon />
            <Box>
              <Box fontSize="12px" fontWeight="700" letterSpacing="-0.5px" lineHeight="12px">
                {item.title}
              </Box>
              <Box fontSize="8px" fontWeight="600" lineHeight="1" mt="4px">
                {item.subtitle}
              </Box>
            </Box>
          </Box>
        </a>
      ))}
    </FlexBox>
  );
};

export default AppStore;
