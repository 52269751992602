import React, { FC } from 'react';

import { useRouter } from 'next/router';

import { Box, Container, IconButton, Link, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import AppStore from 'components/AppStore';
import Image from 'components/BazaarImage';
import { FlexRowCenter } from 'components/flex-box';
import Facebook from 'components/icons/Facebook';
import Instagram from 'components/icons/Instagram';

const FooterDivider = React.memo(() => (
  <Box position="relative" py="4px">
    <Box width="1px" height="100%" bgcolor="#6c6c6c" />
  </Box>
));

const useStyles = makeStyles({
  footerText: {
    fontSize: 12,
    letterSpacing: '-0.5px',
    color: '#AEB4BE'
  }
});

const Footer1: FC = () => {
  const router = useRouter();
  const classes = useStyles();

  // render
  return (
    <footer>
      <Box bgcolor="#222935" sx={(theme) => ({ borderTop: `1px solid ${theme.palette.divider}` })}>
        <Container maxWidth="lg">
          <Box py="27px" fontSize="12px">
            <FlexRowCenter mb="27px" sx={{ cursor: 'pointer' }} onClick={(e) => router.push('/')}>
              <Typography color="#AEB4BE" fontSize="14px" letterSpacing="-0.5px">
                와인과 일상을 잇다.&nbsp; 당신 주변의 와인
              </Typography>
              <Image
                src="/assets/images/logo_lightgreen.webp"
                alt="1KMWINE logo"
                style={{ display: 'inline-block', height: '20px', marginLeft: '4px', marginBottom: '2px' }}
              />
            </FlexRowCenter>

            <FlexRowCenter mb="11px" textAlign="center">
              <Stack direction="row" spacing={0.75} flexWrap="wrap" justifyContent="center" divider={<FooterDivider />}>
                <Link
                  component="button"
                  className={classes.footerText}
                  onClick={(e) => {
                    window.open('/docs/terms-of-service');
                  }}
                >
                  이용약관
                </Link>
                <Link
                  component="button"
                  className={classes.footerText}
                  onClick={(e) => {
                    window.open('/docs/privacy-policy');
                  }}
                >
                  <strong>개인정보 처리방침</strong>
                </Link>
                <Link
                  component="button"
                  className={classes.footerText}
                  onClick={(e) => {
                    window.open('/docs/location-policy');
                  }}
                >
                  위치정보 이용약관
                </Link>
                <Link
                  component="button"
                  className={classes.footerText}
                  onClick={(e) => {
                    window.open('https://vendor.1kmwine.com');
                  }}
                >
                  1KMWINE 사장님
                </Link>
              </Stack>
            </FlexRowCenter>
            <Box textAlign="center" mb="11px" color="white" letterSpacing="-0.5px">
              <Typography
                fontSize="12px"
                color="inherit"
                lineHeight="inherit"
                letterSpacing="inherit"
                position="relative"
                fontWeight={500}
                sx={{ wordBreak: 'keep-all' }}
              >
                와인원㈜은 전자결제서비스제공자 및 통신판매중개자이며 통신판매의 당사자가 아닙니다. 상품, 상품정보 및
                거래에 관한 의무와 책임은 판매자에게 있습니다.
              </Typography>
            </Box>
            <FlexRowCenter mb="11px" maxWidth="sm" mx="auto">
              <Stack
                direction="row"
                spacing={0.75}
                flexWrap="wrap"
                justifyContent="center"
                sx={{ fontSize: '12px' }}
                divider={<FooterDivider />}
              >
                <Typography component="span" className={classes.footerText}>
                  상호명 : 주식회사 와인원
                </Typography>
                <Typography component="span" className={classes.footerText}>
                  사업자등록번호 : 238-88-02348
                </Typography>
                <Typography component="span" className={classes.footerText}>
                  통신판매업신고번호 : 2023-서울강남-00698
                </Typography>
                <Typography component="span" className={classes.footerText}>
                  대표이사 : 송재영
                </Typography>

                {/* todo 사이트에 대표 전화번호 법인폰 변경으로 변경 후 오픈 */}
                {/*<Link*/}
                {/*  component="button"*/}
                {/*  className={classes.footerText}*/}
                {/*  onClick={(e) => {*/}
                {/*    window.open('https://www.ftc.go.kr/bizCommPop.do?wrkr_no=2388802348');*/}
                {/*  }}*/}
                {/*>*/}
                {/*  사업자등록정보확인*/}
                {/*</Link>*/}

                <Typography component="span" className={classes.footerText}>
                  문의메일 : cs@wineone.io
                </Typography>
                <Typography component="span" className={classes.footerText}>
                  대표번호 : 070-7711-3306
                </Typography>
                <Typography component="span" className={classes.footerText}>
                  주소 : 서울 강남구 논현로 152길 9, 도운빌딩, 3층
                </Typography>
              </Stack>
            </FlexRowCenter>

            <FlexRowCenter mt="22px" mb="11px">
              <Box>
                <AppStore />
              </Box>
            </FlexRowCenter>
            <FlexRowCenter my="11px">
              {iconList.map((item, ind) => (
                <a href={item.url} target="_blank" rel="noreferrer noopenner" key={ind}>
                  <IconButton
                    sx={{
                      margin: 0.5,
                      fontSize: 12,
                      padding: '10px',
                      backgroundColor: 'rgba(0,0,0,0.2)'
                    }}
                  >
                    <item.icon fontSize="inherit" sx={{ color: 'white' }} />
                  </IconButton>
                </a>
              ))}
            </FlexRowCenter>

            <FlexRowCenter mb="11px">
              <Typography component="span" className={classes.footerText}>
                Copyright {new Date().getFullYear()} ©
                <a href="https://www.wineone.io" target="_blank" rel="noreferrer">
                  <strong>WineOne Inc.</strong>
                </a>
                &nbsp;All Rights Reserved.
              </Typography>
            </FlexRowCenter>
          </Box>
        </Container>
      </Box>
    </footer>
  );
};

const iconList = [
  { icon: Facebook, url: 'https://www.facebook.com/1kmwine' },
  { icon: Instagram, url: 'https://www.instagram.com/1kmwine_official/' }
];

export default Footer1;
