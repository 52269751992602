import React, { FC } from 'react';
import { useRouter } from 'next/router';

import BazaarImage from 'components/BazaarImage';
import { FlexBox } from 'components/flex-box';

import { Box, Button, Container, Stack, Typography } from '@mui/material';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

const Footer2: FC = () => {
  // 사업자 정보 펼침여부
  const [companyInfoExpanded, setCompanyInfoExpanded] = React.useState<boolean>(false);
  const router = useRouter();
  // render
  return (
    <footer>
      <Box bgcolor="#f3f5f7" sx={(theme) => ({ borderTop: `1px solid ${theme.palette.divider}` })}>
        <Container maxWidth="sm">
          <Box pt={4} pb={2}>
            <BazaarImage
              width={96}
              src="/assets/images/logo-dark.png"
              sx={{ mb: 2, cursor: 'pointer' }}
              onClick={() => {
                router.push('/');
              }}
            />

            <Typography
              fontSize="12px"
              color="#767678"
              lineHeight="18px"
              letterSpacing="-0.3px"
              position="relative"
              fontWeight={500}
              sx={{ wordBreak: 'keep-all' }}
            >
              와인원㈜은 전자결제서비스제공자 및 통신판매중개자이며 통신판매의 당사자가 아닙니다. 상품, 상품정보 및
              거래에 관한 의무와 책임은 판매자에게 있습니다.
            </Typography>
          </Box>
          <Box py={2}>
            <Button
              size="small"
              endIcon={<ExpandMoreRoundedIcon htmlColor="#1e1e23" />}
              sx={{
                pl: 0,
                '& .MuiButton-endIcon': { ml: '2px' },
                '& .MuiSvgIcon-root': { transform: `rotate(${companyInfoExpanded ? 180 : 0}deg)` }
              }}
              onClick={(e) => {
                if (!e.isTrusted) return false;
                setCompanyInfoExpanded((prev) => !prev);
              }}
            >
              <Typography
                component="span"
                fontSize="13px"
                lineHeight="inherit"
                letterSpacing="-0.3px"
                fontWeight={700}
                color="#767678"
                sx={{ mb: '2px' }}
              >
                와인원(주) 사업자정보
              </Typography>
            </Button>

            {companyInfoExpanded && (
              <Box component="dl" mt={1} letterSpacing="-0.3px">
                <Stack spacing={1.5}>
                  <FlexBox>
                    <Typography
                      component="dt"
                      variant="subtitle2"
                      fontSize="12px"
                      fontWeight={600}
                      letterSpacing="inherit"
                      color="#929294"
                      sx={{ width: 100 }}
                    >
                      대표이사
                    </Typography>
                    <Typography
                      component="dd"
                      variant="body2"
                      fontSize="12px"
                      fontWeight={600}
                      letterSpacing="inherit"
                      color="#767678"
                    >
                      송재영
                    </Typography>
                  </FlexBox>

                  <FlexBox>
                    <Typography
                      component="dt"
                      variant="subtitle2"
                      fontSize="12px"
                      fontWeight={600}
                      letterSpacing="inherit"
                      color="#929294"
                      sx={{ width: 100 }}
                    >
                      주소
                    </Typography>
                    <Typography
                      component="dd"
                      variant="body2"
                      fontSize="12px"
                      fontWeight={600}
                      letterSpacing="inherit"
                      color="#767678"
                    >
                      서울 강남구 논현로 152길 9, 도운빌딩, 3층
                    </Typography>
                  </FlexBox>

                  <FlexBox>
                    <Typography
                      component="dt"
                      variant="subtitle2"
                      fontSize="12px"
                      fontWeight={600}
                      letterSpacing="inherit"
                      color="#929294"
                      sx={{ width: 100 }}
                    >
                      문의
                    </Typography>
                    <Typography
                      component="dd"
                      variant="body2"
                      fontSize="12px"
                      fontWeight={600}
                      letterSpacing="inherit"
                      color="#767678"
                    >
                      cs@wineone.io
                    </Typography>
                  </FlexBox>

                  <FlexBox>
                    <Typography
                      component="dt"
                      variant="subtitle2"
                      fontSize="12px"
                      fontWeight={600}
                      letterSpacing="inherit"
                      color="#929294"
                      sx={{ width: 100 }}
                    >
                      사업자등록번호
                    </Typography>
                    <Typography
                      component="dd"
                      variant="body2"
                      fontSize="12px"
                      fontWeight={600}
                      letterSpacing="inherit"
                      color="#767678"
                    >
                      238-88-02348
                    </Typography>
                  </FlexBox>
                  <FlexBox>
                    <Typography
                      component="dt"
                      variant="subtitle2"
                      fontSize="12px"
                      fontWeight={600}
                      letterSpacing="inherit"
                      color="#929294"
                      sx={{ width: 100 }}
                    >
                      통신판매업신고번호
                    </Typography>
                    <Typography
                      component="dd"
                      variant="body2"
                      fontSize="12px"
                      fontWeight={600}
                      letterSpacing="inherit"
                      color="#767678"
                    >
                      2023-서울강남-00698
                    </Typography>
                  </FlexBox>
                </Stack>
              </Box>
            )}
          </Box>
        </Container>
      </Box>
    </footer>
  );
};

export default Footer2;
